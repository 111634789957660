import React, { forwardRef, useCallback, useContext, useState, useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/tr';
import Datetime from 'react-datetime';
import Image from 'next/image';
import Tooltip, { TTooltip } from '../../tooltip/tooltip';
import { EyeOffIcon, EyeOnIcon, PasswordValidateCheck } from '../../icons/icons';
import { DeviceContext } from '@/src/store/contexts/device-context';

interface RadioButtonOption {
  value: string;
  label: string;
}

interface InputFieldProps {
  label: string;
  placeholder?: string;
  name: string;
  type?: string;
  append?: React.ReactNode;
  maxLength?: number;
  required?: boolean;
  children?: React.ReactNode;
  showNoneOption?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  preventEInField?: boolean;
  defaultValue?: string | number;
  overrideInputClassName?: string;
  overrideClassName?: string;
  parseSelectAsString?: boolean;
  selectElementNoneOptionColor?: string;
  isValidDate?: (currentDate: any, selectedDate: any) => boolean;
  hasTooltip?: boolean;
  tooltipType?: string;
  tooltipText?: string;
  tooltipPosition?: 'left' | 'middle' | 'right' | 'bottom' | 'mobileInputMiddle';
  tooltipImgSource?: string;
  tooltipIcon?: React.ReactNode;
  tooltipClassname?: string;
  hasMaxLength?: boolean;
  maxLen?: number;
  noPunc?: boolean;
  noPuncWithComma?: boolean;
  checkNo?: boolean;
  digitOnly?: boolean;
  decimalOnly?: boolean;
  preventZeroFirstChar?: boolean;
  isInterestRate?: boolean;
  isInterestRatePunc?: boolean;
  isPassword?: boolean;
  isPasswordCheck?: boolean;
  hasMinLength?: boolean;
  minLen?: number;
  noDigit?: boolean;
  preventLettersAllowNumbers?: boolean;
  icon?: { containerClassName?: string; className?: string; size?: number };
  overrideLabelClassName?: string;
  overrideErrorClassName?: string;
  overrideInputErrorClassName?: string;
  customErrorMessage?: string;
  maxValue?: number;
  minValue?: number;
  allowNegativeValue?: boolean;
  tooltipRef?: React.Ref<TTooltip>;
  radioButtonOptions?: RadioButtonOption[];
  errorIcon?: React.ReactNode;
  disableBuiltInErrorMessage?: boolean;
}

/**
 * Format a given string for interest rate display.
 */
const formatInterestRate = (value: string): string => {
  const digits = value.replace(/\D/g, '');
  if (!digits) return '';
  if (digits.length === 1) return digits;
  if (digits.length === 2) return `${digits[0]},${digits[1]}`;
  if (digits.length === 3) return `${digits[0]},${digits.slice(1)}`;
  return digits;
};

/**
 * Refactored InputField – uses react-hook-form Controller for a fully controlled input.
 */
const InputField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const {
    label,
    placeholder,
    name,
    type = 'text',
    append,
    maxLength,
    required = false,
    children,
    showNoneOption = '',
    onChange,
    preventEInField = false,
    defaultValue,
    overrideInputClassName = '',
    overrideClassName = '',
    parseSelectAsString = false,
    selectElementNoneOptionColor = '',
    isValidDate,
    hasTooltip = false,
    tooltipType,
    tooltipText,
    tooltipPosition,
    tooltipImgSource,
    tooltipIcon,
    tooltipClassname,
    hasMaxLength = false,
    maxLen,
    noPunc = false,
    noPuncWithComma = false,
    checkNo = false,
    digitOnly = false,
    decimalOnly = false,
    preventZeroFirstChar = false,
    isInterestRate = false,
    isInterestRatePunc = false,
    isPasswordCheck = false,
    isPassword = false,
    hasMinLength = false,
    minLen,
    noDigit = false,
    preventLettersAllowNumbers = false,
    icon,
    overrideLabelClassName,
    overrideErrorClassName,
    overrideInputErrorClassName,
    customErrorMessage,
    maxValue,
    minValue = 0,
    allowNegativeValue = false,
    tooltipRef,
    radioButtonOptions = [],
    errorIcon,
    disableBuiltInErrorMessage = false,
    ...rest
  } = props;

  const { isMobile } = useContext(DeviceContext);
  const {
    control,
    formState: { errors, touchedFields },
  } = useFormContext();

  // Local state for password toggling and validations.
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [isEightDigitsChecked, setIsEightDigitsChecked] = useState(false);
  const [isCapitalCharChecked, setIsCapitalCharChecked] = useState(false);
  const [isNumericCharChecked, setIsNumericCharChecked] = useState(false);

  // Used when checkNo is enabled.
  const leadingZerosRef = useRef<string>('');

  // Helper to merge multiple refs.
  const mergeRefs = (...refs: any[]) => {
    return (node: any) => {
      refs.forEach((refItem) => {
        if (typeof refItem === 'function') {
          refItem(node);
        } else if (refItem) {
          refItem.current = node;
        }
      });
    };
  };

  // KeyDown handler to prevent unwanted keys.
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const forbiddenPunc = [
        ',',
        '?',
        ';',
        '!',
        ':',
        "'",
        '(',
        ')',
        '[',
        ']',
        '{',
        '}',
        '"',
        '/',
        '@',
        '-',
        '*',
        '_',
        '=',
        '^',
        '%',
        '&',
        '+',
      ];
      if ((noPunc && forbiddenPunc.includes(event.key)) || (noPuncWithComma && event.key === '.')) {
        event.preventDefault();
      }
      if (noDigit && /\d/.test(event.key)) {
        event.preventDefault();
      }
      if ((type === 'number' || preventEInField) && (event.key === 'e' || event.key === 'E')) {
        event.preventDefault();
      }
      const target = event.currentTarget as HTMLInputElement;
      if (hasMaxLength && maxLen && target.value.length >= maxLen && event.code !== 'Backspace') {
        event.preventDefault();
      }
      if (digitOnly && event.key.length === 1 && isNaN(Number(event.key)) && event.code !== 'Backspace') {
        event.preventDefault();
      }
      if (
        decimalOnly &&
        event.key.length === 1 &&
        isNaN(Number(event.key)) &&
        event.code !== 'Backspace' &&
        event.key !== ','
      ) {
        event.preventDefault();
      }
      if (preventLettersAllowNumbers && !/\d/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete') {
        event.preventDefault();
      }
    },
    [
      noPunc,
      noPuncWithComma,
      noDigit,
      type,
      preventEInField,
      hasMaxLength,
      maxLen,
      digitOnly,
      decimalOnly,
      preventLettersAllowNumbers,
    ]
  );

  const handlePriceKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const forbidden = [
        ',',
        '?',
        ';',
        '!',
        ':',
        "'",
        '(',
        ')',
        '[',
        ']',
        '{',
        '}',
        '"',
        '/',
        '@',
        '-',
        '*',
        '_',
        '=',
        '^',
        '%',
        '&',
        '+',
      ];
      if ((noPunc && forbidden.includes(e.key)) || (noPuncWithComma && e.key === '.')) {
        e.preventDefault();
      }
      const target = e.target as HTMLInputElement;
      if (hasMaxLength && maxLen && target.value.length >= maxLen) {
        e.preventDefault();
      }
    },
    [noPunc, noPuncWithComma, hasMaxLength, maxLen]
  );

  const handleLeadingZeros = useCallback((value: string) => {
    leadingZerosRef.current = (leadingZerosRef.current + value).slice(-9);
    return leadingZerosRef.current.padStart(9, '0');
  }, []);

  const checkPasswordRegexes = useCallback((value: string) => {
    setIsEightDigitsChecked(/.{8,}/.test(value));
    setIsCapitalCharChecked(/(?=.*[A-Z])/.test(value));
    setIsNumericCharChecked(/(?=.*\d)/.test(value));
  }, []);

  // Common CSS classes.
  const commonInputClasses = 'h-[52px] md:h-[56px] p-4 md:px-5 md:py-4 bg-white border border-solid rounded-lg w-full';
  const commonTextClasses = 'text-black800 autofill:text-black800 text-sm font-semibold md:text-base';
  const commonSelectClasses = 'text-sm font-semibold md:text-base';
  const inputBorderClass = classNames('border-black300', {
    'border-black500': false, // You can add dynamic error classes here if desired
  });
  const overriddenInputClass = overrideInputClassName
    ? classNames(overrideInputClassName, {
        [overrideInputErrorClassName ?? '']: errors[name] && touchedFields[name],
      })
    : classNames(
        commonInputClasses,
        { [commonTextClasses]: type !== 'select' },
        { [commonSelectClasses]: type === 'select' },
        inputBorderClass
      );

  return (
    <div className={classNames(overrideClassName || 'flex grow flex-col')}>
      {label && (
        <div className="mb-2 flex items-center gap-1">
          <label
            className={classNames(overrideLabelClassName ?? 'text-xs font-semibold md:text-sm')}
            data-testid={`${name}Label`}
          >
            {label}
          </label>
          {hasTooltip && (
            <Tooltip
              tooltipType={tooltipType}
              tooltipText={tooltipText}
              tooltipIcon={tooltipIcon}
              tooltipClassname={tooltipClassname}
              className="ml-1 text-xs md:text-base"
              ref={tooltipRef}
              position={tooltipPosition}
              optionalClasses={
                tooltipType === 'info' && tooltipImgSource ? 'md:w-[600px] w-[300px]' : 'md:w-[238px] w-[202px]'
              }
            >
              {tooltipType === 'info' && tooltipImgSource ? (
                <Image src={tooltipImgSource} alt={label} width={isMobile ? 300 : 600} height={isMobile ? 300 : 600} />
              ) : (
                tooltipText
              )}
            </Tooltip>
          )}
        </div>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? (type === 'number' ? 0 : '')}
        rules={{ required }}
        render={({ field }) => {
          // Centralized change handler that applies all our custom formatting.
          const handleControlledChange = (
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
          ) => {
            let value = e.target.value;
            if (preventZeroFirstChar && value.startsWith('0')) {
              value = value.substring(1);
            }
            if (isInterestRate) {
              value = formatInterestRate(value);
            }
            if (type === 'price') {
              const rawValue = value.replace(/\D/g, '');
              let parsedValue = parseInt(rawValue, 10) || 0;
              if (parsedValue < minValue) {
                parsedValue = minValue;
              }
              if (maxValue !== undefined && parsedValue > maxValue) {
                parsedValue = maxValue;
              }
              if (!allowNegativeValue && parsedValue < 0) {
                parsedValue = 0;
              }
              value = new Intl.NumberFormat('tr').format(parsedValue);
            }
            if (checkNo) {
              value = handleLeadingZeros(value);
            }
            field.onChange(value);
            if (onChange) {
              onChange(e);
            }
          };

          switch (type) {
            case 'select':
              return (
                <select
                  data-testid={name}
                  name={name}
                  value={field.value ?? ''}
                  onChange={handleControlledChange}
                  onBlur={field.onBlur}
                  ref={mergeRefs(field.ref, ref)}
                  className={classNames(overriddenInputClass, {
                    [selectElementNoneOptionColor]: selectElementNoneOptionColor && field.value === '',
                  })}
                  {...rest}
                  // defaultValue={showNoneOption ? '' : field.value}
                >
                  {showNoneOption && (
                    <option value="" hidden>
                      {showNoneOption}
                    </option>
                  )}
                  {children}
                </select>
              );
            case 'file':
              return (
                <input
                  data-testid={name}
                  type="file"
                  name={name}
                  onChange={(e) => {
                    const files = (e.target as HTMLInputElement).files;
                    field.onChange(files);
                    if (onChange) onChange(e);
                  }}
                  onBlur={field.onBlur}
                  ref={mergeRefs(field.ref, ref)}
                  className={overriddenInputClass}
                  {...rest}
                />
              );
            case 'textarea':
              return (
                <textarea
                  data-testid={name}
                  name={name}
                  value={field.value ?? ''}
                  onChange={handleControlledChange}
                  onBlur={field.onBlur}
                  ref={mergeRefs(field.ref, ref)}
                  placeholder={placeholder}
                  className={overriddenInputClass}
                  {...rest}
                />
              );
            case 'datetime':
              return (
                <Datetime
                  data-testid={name}
                  isValidDate={isValidDate}
                  locale="tr"
                  //@ts-ignore
                  value={field.value ? moment(field.value, 'DD/MM/YYYY').toDate() : null}
                  onChange={(value) => {
                    const formatted = typeof value === 'string' ? value : moment(value).format('DD/MM/YYYY');
                    field.onChange(formatted);
                  }}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  inputProps={{
                    // readOnly: true,
                    placeholder,
                    className: overriddenInputClass,
                    ref: mergeRefs(field.ref, ref),
                    onBlur: field.onBlur,
                  }}
                  {...rest}
                />
              );
            case 'price':
              return (
                <div className="relative">
                  <input
                    type="text"
                    data-testid={name}
                    name={name}
                    value={field.value ?? ''}
                    onChange={handleControlledChange}
                    onBlur={field.onBlur}
                    onKeyDown={handlePriceKeyDown}
                    className={overriddenInputClass}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    {...rest}
                    ref={mergeRefs(field.ref, ref)}
                  />
                  {append && <span className="absolute right-2 top-1/2 -translate-y-1/2">{append}</span>}
                </div>
              );
            case 'passwordWithEyeIcon':
              return (
                <div className="group relative inline-block">
                  <input
                    data-testid={name}
                    type={passwordFieldType}
                    name={name}
                    value={field.value ?? ''}
                    onChange={(e) => {
                      handleControlledChange(e);
                      checkPasswordRegexes(e.target.value);
                    }}
                    onBlur={field.onBlur}
                    placeholder={placeholder}
                    onKeyUp={(e) => {
                      const target = e.currentTarget;
                      if (hasMaxLength && maxLen && target.value.length > maxLen) {
                        target.value = target.value.slice(0, maxLen);
                        e.preventDefault();
                      }
                    }}
                    className={overriddenInputClass}
                    {...rest}
                    ref={mergeRefs(field.ref, ref)}
                  />
                  <span
                    className={
                      icon?.containerClassName ??
                      classNames('absolute right-2 top-1/2 -translate-y-1/2', {
                        'top-[30px]': !isPasswordCheck,
                      })
                    }
                  >
                    {passwordFieldType === 'password' ? (
                      <EyeOffIcon
                        className={icon?.className ?? 'cursor-pointer stroke-[#888888]'}
                        width={icon?.size ?? 20}
                        height={icon?.size ?? 20}
                        onClick={() => setPasswordFieldType('text')}
                      />
                    ) : (
                      <EyeOnIcon
                        className={icon?.className ?? 'cursor-pointer stroke-[#888888]'}
                        width={icon?.size ?? 20}
                        height={icon?.size ?? 20}
                        onClick={() => setPasswordFieldType('password')}
                      />
                    )}
                  </span>
                  {customErrorMessage && (
                    <div className="mt-1">
                      <span className="text-xs text-error">{customErrorMessage}</span>
                    </div>
                  )}
                  {!isPasswordCheck && (
                    <div className="mt-1 flex gap-4">
                      <div className="flex items-center gap-1">
                        <PasswordValidateCheck
                          width="16px"
                          height="16px"
                          className="text-neutral400 group-focus-within:text-purple404"
                          style={{
                            color: isEightDigitsChecked ? '#16A34A' : undefined,
                          }}
                        />
                        <span
                          className="text-xs text-neutral400 group-focus-within:text-purple404"
                          style={{
                            color: isEightDigitsChecked ? '#16A34A' : undefined,
                          }}
                        >
                          En az 8 karakter
                        </span>
                      </div>
                      <div className="flex items-center gap-1">
                        <PasswordValidateCheck
                          width="16px"
                          height="16px"
                          className="text-neutral400 group-focus-within:text-purple404"
                          style={{
                            color: isCapitalCharChecked ? '#16A34A' : undefined,
                          }}
                        />
                        <span
                          className="text-xs text-neutral400 group-focus-within:text-purple404"
                          style={{
                            color: isCapitalCharChecked ? '#16A34A' : undefined,
                          }}
                        >
                          En az 1 büyük harf
                        </span>
                      </div>
                      <div className="flex items-center gap-1">
                        <PasswordValidateCheck
                          width="16px"
                          height="16px"
                          className="text-neutral400 group-focus-within:text-purple404"
                          style={{
                            color: isNumericCharChecked ? '#16A34A' : undefined,
                          }}
                        />
                        <span
                          className="text-xs text-neutral400 group-focus-within:text-purple404"
                          style={{
                            color: isNumericCharChecked ? '#16A34A' : undefined,
                          }}
                        >
                          En az 1 rakam
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            case 'radio':
              return (
                <div className="flex gap-8">
                  {radioButtonOptions.map((option) => (
                    <label key={option.value} className="flex items-center gap-2">
                      <input
                        type="radio"
                        name={name}
                        value={option.value}
                        checked={field.value === option.value}
                        onChange={() => field.onChange(option.value)}
                        className="peer hidden"
                        {...rest}
                      />
                      <div className="h-4 w-4 rounded-full border-2 border-gray25 peer-checked:border-4 peer-checked:border-purple404 peer-checked:bg-white" />
                      <span className="text-sm text-neutral900">{option.label}</span>
                    </label>
                  ))}
                </div>
              );
            default:
              return (
                <div className="relative inline-block">
                  <input
                    data-testid={name}
                    type={type}
                    name={name}
                    value={field.value ?? ''}
                    onChange={(e) => {
                      handleControlledChange(e);
                      if (isInterestRatePunc && decimalOnly) {
                        let val = e.target.value.replace(/^%/, '').replace(/,+/g, '');
                        if (/^\d+$/.test(val)) {
                          if (val.length <= 2) {
                            e.target.value = `%${val}`;
                          } else if (val.length === 3 && val === '100') {
                            e.target.value = `%100`;
                          } else if (val.length <= 4) {
                            const integerPart = val.slice(0, val.length - 2);
                            const decimalPart = val.slice(-2);
                            e.target.value = `%${integerPart},${decimalPart}`;
                          } else {
                            e.target.value = '%99,99';
                          }
                        } else {
                          e.target.value = '%';
                        }
                      }
                    }}
                    onKeyUp={(e) => {
                      const target = e.currentTarget;
                      if (hasMaxLength && maxLen && target.value.length > maxLen) {
                        target.value = target.value.slice(0, maxLen);
                        e.preventDefault();
                      }
                      if (hasMinLength && minLen && target.value.length < minLen) {
                        e.preventDefault();
                      }
                    }}
                    onKeyDown={handleKeyDown}
                    onBlur={field.onBlur}
                    placeholder={placeholder}
                    className={overriddenInputClass}
                    {...rest}
                    ref={mergeRefs(field.ref, ref)}
                  />
                  {append && <span className="absolute right-2 top-1/2 -translate-y-1/2">{append}</span>}
                </div>
              );
          }
        }}
      />
      {errors[name] && touchedFields[name] && !isPassword && !disableBuiltInErrorMessage && (
        <div className="relative">
          <p
            className={overrideErrorClassName ?? 'absolute text-xs text-error md:text-sm'}
            data-testid={`${name}Error`}
          >
            {errorIcon} {errors[name]?.message as React.ReactNode}
          </p>
        </div>
      )}
    </div>
  );
});

InputField.displayName = 'InputField';
export default InputField;
